import axios from "axios";

export const axiosbaseurl = axios.create({
  baseURL: "https://propusers.com/admin/api/",
  // baseURL: "https://ztpl.net/propuser/crm/api/",
});

const config = {
  appUrl: "/",
  // demo.
};

export default config;